/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/firestore"; // Import the Firestore service

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBGO2hWACqs0RyBf287KB5wAl7dk9pV-4w",
  authDomain: "s2proboticsemail.firebaseapp.com",
  projectId: "s2proboticsemail",
  storageBucket: "s2proboticsemail.appspot.com",
  messagingSenderId: "585639866034",
  appId: "1:585639866034:web:317d2d589d7dc0b6af63ab",
  measurementId: "G-9YHT5FSXXE",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
