/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
//import Grid from "@mui/material/Grid";
//import Link from "@mui/material/Link";
//import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
//import MKButton from "components/MKButton";
//import MKTypography from "components/MKTypography";

// Images
//import bgImage from "assets/images/bg-coworking.jpeg";
import bgVideo from "assets/images/Ns2p.mp4";

function HeaderOne() {
  return (
    <MKBox component="header" position="relative" height="100%">
      <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
        <Container></Container>
      </MKBox>
      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100%"
        sx={{
          // Clear the backgroundImage property
          backgroundImage: "none",
          // Set styles for the video
          position: "relative", // Ensure positioning context
          zIndex: 0, // Ensure it's behind other content
        }}
      >
        {/* Add the video element */}
        <video
          autoPlay
          loop
          muted
          controls
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        >
          {/* Add video sources */}
          <source src={bgVideo} type="video/mp4" />
          {/* Add additional video sources if needed */}
        </video>
      </MKBox>
    </MKBox>
  );
}

export default HeaderOne;
