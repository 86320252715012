import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import bgVideo1 from "assets/images/number.mp4";
import bgVideo2 from "assets/images/fire.mp4";
import bgVideo3 from "assets/images/weapon.mp4";
import bgVideo4 from "assets/images/human.mp4";
import bgVideo5 from "assets/images/safety.mp4";
import bgVideo6 from "assets/images/area.mp4";
import bgVideo7 from "assets/images/agree.mp4";
import bgVideo8 from "assets/images/dent1.mp4";
import bgVideo9 from "assets/images/defects1.mp4";
import bgVideo10 from "assets/images/agree.mp4";
import bgVideo11 from "assets/images/food2.mp4";
import bgVideo12 from "assets/images/whatsapp.mp4";
import bgVideo13 from "assets/images/alarm.mp4";
import bgVideo14 from "assets/images/mobile.mp4";
import bgVideo15 from "assets/images/glob.mp4";

import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

function Information() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Initialize Firebase Firestore
    const db = firebase.firestore();

    // Add a new document with a generated id.
    db.collection("messages")
      .add(formData)
      .then(() => {
        console.log("Message sent successfully");
        alert("Sucessfully Message sent");
        setFormData({
          fullName: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Error sending message: ", error);
        alert("Message Not Sent");
      });
  };

  return (
    <MKBox component="section" py={0}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2" color="info" textGradient mb={2}>
            Video Demo
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8, width: "100%", height: "100%" }}>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo1} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Number Plate Detection"
                review="Our AI camera's number plate detection feature offers enhanced security, streamlined access control, and efficient parking management. It aids law enforcement by tracking stolen vehicles and identifying traffic violations."
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Fire Detection"
                review="Our AI camera's fire detection feature is designed to rapidly identify and alert users to the presence of fires or smoke within a monitored area. By leveraging advanced algorithms, the camera can detect the unique visual characteristics associated with flames or smoke."
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo3} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Weapon Detection"
                review="Our AI camera's weapon detection feature is designed to identify and alert users to the presence of weapons and humans within a monitored area. This feature allow algorithms to analyze video feeds in real-time."
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo4} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Human Detection"
                review="Human detection in AI camera systems ensures improved safety and security by identifying unauthorized individuals in restricted areas. It enhances surveillance capabilities, enabling prompt response to potential threats or emergencies. "
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo5} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Safety Gear Detection"
                review="
                Safety gear detection in AI camera systems enhances workplace safety by identifying individuals who are not wearing required safety equipment such as helmets, vests, or goggles. It ensures compliance with safety regulations, minimizes the risk of accidents, and promotes a culture of safety awareness."
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo6} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Area Detection"
                review="Work area detection in AI camera systems ensures safety by monitoring and delineating designated work zones within industrial or construction sites. It helps prevent unauthorized access, reducing the risk of accidents and injuries. "
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo7} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Agriculture Detection"
                review="Agriculture detection in AI camera systems aids in precision farming by identifying crops, monitoring their growth stages, and detecting potential issues such as pests or diseases. It enables farmers to make data-driven decisions for optimized irrigation, fertilization, and crop management"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo8} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Dent Detection"
                review="Dent detection in AI camera systems allows for the automated identification and analysis of dents or damage on surfaces such as vehicles or structures. It assists in quality control processes, enabling timely repairs and maintenance to uphold aesthetic appeal and structural integrity."
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo9} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Defect Detection"
                review="Defects detection in AI camera systems for cars involves automated identification and analysis of imperfections or faults in various vehicle components. It aids in quality assurance during manufacturing processes, ensuring that vehicles meet specified standards before reaching the market"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo10} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Object Counting"
                review="
                Object counting in AI camera systems involves accurately tallying the number of items within a specified area or scene. It aids in various applications such as retail analytics, traffic monitoring, and crowd management. "
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo11} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Food Segregation"
                review="
                Food segregation in AI camera systems involves the automated sorting and categorization of different types of food items based on visual characteristics such as shape, color, or texture. It aids in food processing and packaging industries by streamlining production lines"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo12} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="WhatsApp Notification"
                review="provide real-time alerts to users when a vehicle's number plate is detected. This notification system can include details such as the detected number plate, timestamp, and location. It enhances security and facilitates prompt action in scenarios such as parking management, law enforcement, or access control."
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo13} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Live Alarm"
                review="live alarms for fire detection and theft are provided to ensure immediate awareness and response to potential threats. These alarms are triggered in real-time, notifying all relevant stakeholders, such as property owners, security personnel, or emergency services."
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo14} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Android Assess"
                review="
                Incorporating an Android mobile app enables users to remotely monitor camera feeds from their smartphones or tablets. This app provides convenient access to live video streams, allowing users to keep an eye on their premises or assets from anywhere with internet connectivity"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo15} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Global Assess"
                review="
                By offering global access, users can remotely access their camera feeds from anywhere in the world. This feature allows individuals to monitor their camera systems and surveillance footage regardless of their location, providing convenience and peace of mind."
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={12} style={{ height: "100%", width: "100%" }}>
            <MKBox
              bgColor="#ffffff"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 20, sm: 18, md: 20 }}
              mb={{ xs: 20, sm: 18, md: 20 }}
              mx={3}
            >
              <MKBox
                variant="gradient"
                bgColor="info"
                coloredShadow="info"
                borderRadius="lg"
                p={2}
                mx={2}
                mt={-3}
              >
                <MKTypography variant="h3" color="#131111">
                  For Custom Ai Model For your Company Contact Us !
                </MKTypography>
              </MKBox>
              <MKBox p={3}>
                <MKTypography variant="body2" color="text" mb={3}>
                  For further questions, including partnership opportunities, please email
                  support@s2probotics.com or contact using our contact form.
                </MKTypography>
                <MKBox
                  width="100%"
                  component="form"
                  method="post"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Full Name"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        type="email"
                        variant="standard"
                        label="Email"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        variant="standard"
                        label="What can we help you?"
                        placeholder="Describe your problem in at least 250 characters"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        fullWidth
                        rows={6}
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                    <MKButton type="submit" variant="gradient" color="info">
                      Send Message
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
      </Container>
    </MKBox>
  );
}

export default Information;
