/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import View from "layouts/sections/components/View";
//import Testimonials from "pages/Presentation/sections/Testimonials";

import Video from "pages/Presentation/sections/video2";
//import Contactus from "pages/LandingPages/ContactUs/index";

// PageHeaders page components
import HeaderOne from "layouts/sections/page-sections/page-headers/components/HeaderOne";

// PageHeaders page components code
import HeaderOneCode from "layouts/sections/page-sections/page-headers/components/HeaderOne/code";

function PageHeaders() {
  return (
    <BaseLayout
      title="Omnibot"
      breadcrumb={[
        { label: "Product Sections", route: "/sections/page-sections/page-headers" },
        { label: "Omnibot" },
      ]}
    >
      <View title="Overview" height="40rem" code={<HeaderOneCode />}>
        <HeaderOne />
      </View>
      <Video />
    </BaseLayout>
  );
}

export default PageHeaders;
