/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/ceo.jpeg";
import team2 from "assets/images/cto2.jpeg";
import team3 from "assets/images/amar4.jpeg";
import team4 from "assets/images/amit.jpeg";
import team5 from "assets/images/ritesh2.jpeg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              The Executive Team
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              There&apos;s nothing I really wanted to do in life that I wasn&apos;t able to get good
              at. That&apos;s my skill.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Pradyumna mohani"
                position={{ color: "info", label: "Director - CEO" }}
                description="— Pradyumna Mohani is a dynamic Founder at S2P Robotics Pvt. Ltd., a pioneering AI-driven robotics company focused on developing cutting-edge technology solutions for the Indian market."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Rohit Solanki"
                position={{ color: "info", label: "CTO" }}
                description="— Rohit Solunki passionate about leveraging the power of Artificial Intelligence and Machine Learning to create invensions"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Amarnath Gangurde"
                position={{ color: "info", label: "R&D Head" }}
                description="— Amarnath Gangurde, a seasoned DevOps engineer with expertise in Linux administration, AWS cloud services. "
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Amit Patange"
                position={{ color: "info", label: "Development Head" }}
                description="— Amit Patange, a seasoned full-stack developer proficient in a wide array of technologies."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team5}
                name="Ritesh Tamboli"
                position={{ color: "info", label: "Product Manager" }}
                description="— Ritesh Tamboli, and I'm passionate about creating captivating UI/UX designs using React.js"
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
