/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Feel the
                    <br />
                    ROBOTICS
                  </>
                }
                description="We're committed to democratizing robotics, ensuring that every household can benefit from the transformative power of AI-driven automation."
              />
              <RotatingCardBack
                image={bgBack}
                title="OmniBot"
                description="OmniBot is a versatile service robot crafted to tackle tasks across industries, households, and hotels. From inventory management to home chores and guest services, OmniBot streamlines operations and enhances experiences with its advanced AI and robotics technology"
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "About Omnibot",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="apps"
                  title="Teachable"
                  description="Robots can be taught different actions by performing various activities around it. Through a process known as supervised learning"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Intelligent"
                  description="It can analyze and process data, recognize patterns, and make decisions based on the information they gather. They are capable of performing complex tasks with speed, accuracy and efficiency."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="computer"
                  title="Autonomous"
                  description="Robots can learn itself by gathering inputs through its surroundings. It is designed to learn and adapt to its environment and tasks without the need for explicit programming."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="Machine Learning"
                  description="AI models got trained on device itself. The benefits of that no need of all time internet connectivity to robot. And also data privacy got maintained"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
