import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import { useState } from "react";
import MKButton from "components/MKButton";

import bgVideo1 from "assets/images/0410(1).m4v";
import bgVideo2 from "assets/images/0410(2).m4v";
import bgVideo3 from "assets/images/0410(3).m4v";
import bgVideo4 from "assets/images/0410(4).m4v";
import bgVideo5 from "assets/images/0410(5).m4v";
import bgVideo6 from "assets/images/trash.m4v";

import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

function Information() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Initialize Firebase Firestore
    const db = firebase.firestore();

    // Add a new document with a generated id.
    db.collection("messages")
      .add(formData)
      .then(() => {
        console.log("Message sent successfully");
        setFormData({
          fullName: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Error sending message: ", error);
      });
  };
  return (
    <MKBox component="section" py={0}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2" color="info" textGradient mb={2}>
            Video Demo
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8, width: "100%", height: "100%" }}>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo1} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Chair Arrangement"
                review="Our cutting-edge ROS robot excels at identifying chairs and adeptly maneuvering them to precisely designated locations, showcasing its unparalleled precision and efficiency in task completion."
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Object Interaction"
                review="Our advanced robotic system seamlessly interacts with pots and utensils, effortlessly transporting them to their designated storage locations with precision and efficiency"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo3} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Cooking"
                review="Utilizing the latest advancements in robotics, our system is engineered to undertake sophisticated cooking tasks with unparalleled precision, orchestrating culinary masterpieces with finesse and expertise."
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo4} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Dish Washing"
                review="Employing s2p robotics, our system excels in dishwashing, seamlessly managing the meticulous task with unparalleled efficiency, leaving dishes sparkling clean with precision and speed."
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo5} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="House Cleaning "
                review="Powered by cutting-edge robotics, our system revolutionizes house cleaning, effortlessly tackling every task with unparalleled efficiency and precision, ensuring a spotless and immaculate living environment."
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4} style={{ height: "100%" }}>
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              loop
              muted
              style={{ borderRadius: "12px", backgroundColor: "#23272c" }} // Applying rounded border style
            >
              <source src={bgVideo6} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Grid>
              <DefaultReviewCard
                name="Trash Disposal"
                review="Empowered by cutting-edge robotics, our system seamlessly manages trash disposal, efficiently and hygienically handling waste removal with precision and speed, ensuring a clean and tidy environment."
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={12} style={{ height: "100%" }}>
            <MKBox
              bgColor="#ffffff"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 20, sm: 18, md: 20 }}
              mb={{ xs: 20, sm: 18, md: 20 }}
              mx={3}
            >
              <MKBox
                variant="gradient"
                bgColor="info"
                coloredShadow="info"
                borderRadius="lg"
                p={2}
                mx={2}
                mt={-3}
              >
                <MKTypography variant="h3" color="#131111">
                  For Custom Robots For your Company Contact Us !
                </MKTypography>
              </MKBox>
              <MKBox p={3}>
                <MKTypography variant="body2" color="text" mb={3}>
                  For further questions, including partnership opportunities, please email
                  support@s2probotics.com or contact using our contact form.
                </MKTypography>
                <MKBox
                  width="100%"
                  component="form"
                  method="post"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Full Name"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        type="email"
                        variant="standard"
                        label="Email"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        variant="standard"
                        label="What can we help you?"
                        placeholder="Describe your problem in at least 250 characters"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        fullWidth
                        rows={6}
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                    <MKButton type="submit" variant="gradient" color="info">
                      Send Message
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
      </Container>
    </MKBox>
  );
}

export default Information;
