/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// const imagesPrefix =
//   "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/pages";

export default [
  {
    image: `https://imgs.search.brave.com/hC1CYyWl1YxQFXCSprV5WyQ3EOT2l23hdop8lEw4Z5M/rs:fit:860:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAxLzA4LzM0LzUz/LzM2MF9GXzEwODM0/NTM1OF9tUkVJZWJy/TlhKTTBpdUFseHZF/c2hqMWoyUXJoaHpw/Sy5qcGc`,
    name: "About Us",
    route: "/pages/landing-pages/about-us",
  },
  {
    image: `https://imgs.search.brave.com/U67as4dxHS-X_ov_SuK6jFwK9O937nH_swVt-TbqBXo/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9idXJz/dC5zaG9waWZ5Y2Ru/LmNvbS9waG90b3Mv/YS1waG9uZS1vbi1h/LWJsdWUtYmFja2dy/b3VuZC13aXRoLWNv/bnRhY3QtdXMuanBn/P3dpZHRoPTEwMDAm/Zm9ybWF0PXBqcGcm/ZXhpZj0wJmlwdGM9/MA`,
    name: "Contact Us",
    route: "/pages/landing-pages/contact-us",
  },
  {
    image:
      "https://imgs.search.brave.com/e4fL8ANrlodvbBgloy8600ninMmU0Ndmqh4KFEZ5kvw/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9jZG4u/d2FsbHBhcGVyc2Fm/YXJpLmNvbS81OC8y/MC9DMnBGU2MuanBn",
    name: "S2P Eye",
    route: "/sections/page-sections/features",
  },
  {
    image:
      "https://imgs.search.brave.com/DMtRZppk86z89gH9C3Ihg5NIoxNU0tqYvLEgKInxNtQ/rs:fit:860:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzA1Lzk5LzAxLzI3/LzM2MF9GXzU5OTAx/Mjc5N19uQVVUaVZJ/bTJWZklTVWhKbFhK/ZDZ5WDhqaG56eEVt/Ni5qcGc",
    name: "Omnibot",
    route: "/sections/page-sections/page-headers",
  },
];
